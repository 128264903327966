.technologies{
    min-height: 100vh;
    height: fit-content;
    position: relative;

    content-visibility: auto
}

.technologies::after{
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -1;
    background: url('./images/background.webp') no-repeat;
    background-size: cover;
    
    filter: blur(2px);
    opacity: .7;
}

/* Title */

.technologies .tech-title{
    font-size: 2.5rem;
    color: white;

    width: 90%;
    padding: 5% 0 1%  0;
    text-align: center;
    margin: 0 auto;
    border-bottom: 1px solid white;
    height: 20%;
}

/* Info container */
.technologies .container{
    min-height: 80%;
    height: fit-content;
    width: 100%;
    
    position: absolute;

    display: flex;
    justify-content: space-around;
    align-content: center;
    align-items: center;
}

/* Info */
.technologies .tech-info{
    min-height: 48%;
    position: relative;
    width: 90%;
    display: flex;
    justify-content: space-around;
}

/* Media Querys */
@media(max-width: 1000px){
    .technologies .container{
          position: initial;
          margin: 5% auto 5% auto;
    }
    .technologies .tech-info{
        flex-direction: column;
        align-items: center;
        padding-bottom: 30px;
    }
}