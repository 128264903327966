:root{
    --base: #000000 ;
    --basic: #fff;
    --resalt: #E06000;
    --main:#BDB5C9;
    --replica1: #454D46;
    --replica2: #522735
}

body{
    background-color: #222;
    font-weight: 500 ;
}

a, .a-button{
    text-decoration: none;
    color: inherit;
}


h1, h2, h3, h4, h5, h6{
    white-space: nowrap;
}