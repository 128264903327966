.projects{
    height: fit-content;
    min-height: 100vh;
    width: 100%;

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    content-visibility: auto
}

.projects::after{
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100%;
    z-index: -1;
    background: url('./images/projects-background.webp') fixed ;
    background-position: center;
    background-size: cover;

    filter: blur(5px);
    opacity: .5;
}

/* Header */
.projects .projects-header{
    height: 20%;
    width: 90%;
    
    color: var(--basic);
    font-size: 2.5rem ;
    text-align: center;
    
    padding-bottom: 1%;
    border-bottom: 1px white solid;
    margin: 2% 0;
}

/* Projects list */
.projects .projects-list{
    height: 80vh;
    width: 100%;

    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: center;
}



@media(max-width: 1000px){
    .projects{
        height: fit-content;
        padding-bottom: 3%;
    }
    .projects .projects-list{
        display: block;
        width: 100%;
        height: fit-content;
    }
}