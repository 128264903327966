.contactForm{
    margin: 5vh 2vw;
    height: 100%;
}
/* Header */
.contactForm>.formHeaderContainer h3{
    font-size: 2.5rem;
    white-space: nowrap;
}

.formHeaderContainer{
    height: 10%;
    border-bottom: white solid 1px;
    margin-bottom: 6vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.closeFormImage{
    width: 60px;
    color: white;
}
.closeFormImage:hover{
    cursor: pointer;
}

/* Form */
.contactForm>form{
    height: 100%;
}

.formContainer{
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
}

.contactForm>form{
    border: none;
    top: 0;
    height: 70%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.contactForm>form  label::after{
    content: ':';
}

/* Inputs + Labels */
.inputContainer{
    width: 100%;
    height: 30%;
    display: flex;
    align-items: baseline;
}

.inputError{
    outline: 1.8px solid red;
}

/* Labels */

.inputContainer label{
    font-size: 1.5rem;
    width: 20%;
}

/* Inputs */
.inputContainer input{
    height: 30px;
    margin-left: 10px;
    width: 90%;

    padding-left: 5px;

    font-weight: 600;
}

.inputContainer input::placeholder{
    padding: 0 5px;
}

/* Textarea */
.inputContainer textarea{
    height: 100%;
    margin-left: 10px;
    width: 90%;
    resize: vertical;

    padding: 0 10px;
    padding-top: 5px;

    font-weight: 600;
}

.inputContainer textarea::placeholder{
    padding-left: 5px;
}

.inputContainer-input{
    height: 25%;
}
.inputContainer-textarea{
    height: 50%;
}

.inputContainer textarea:focus, .inputContainer input:focus{
    outline: 1.8px solid green;
}


/* Button section */
.btn-container{
    border-top: white solid 1px;
    height: 10%;
    text-align: center;
    padding-top: 20px;
    margin-bottom: 5px;
}

.btn-container>button{
    height: 35px;
    width: 150px;
    background-color:black;
    color: white;
    border: solid white 2px;
    font-size: 20px;
    margin-bottom: 1px;
    border-radius: 2px;

    transition: all .2s ease-out;
}

.btn-container>button:focus{
    outline: none;
}

.btn-container>button:hover{
    background-color:white;
    color: black;
    border-color: gray
}

/* Message */
.message{
    text-align: center;
    margin-top: 8px;
    margin-bottom: 5px;
}

.msg-error{
    color: rgb(228, 27, 27);
    font-style: italic;
}
.msg-info{
     color: rgb(78, 202, 78);
}

.errorImageContainer{
    display: flex;
    align-items: baseline;
    width: 100%;
    height: 80%;
}

.errorImage{
  width: 20px;
  margin-left: 5px;
  position: relative;
  top: 5px
}

/* Media querys */
@media(max-height: 700px){
    .contactForm>.formHeaderContainer h3{
        font-size: 1.5rem;
    }
    .formHeaderContainer{
        margin-bottom: 10px;
    }
    .formContainer div label{
        font-size: .8rem;
    }
    .inputContainer input{
    height: 18px;
    }

    .inputContainer input, .inputContainer textarea{
        margin-left: 0;
    }

    .inputContainer textarea{
        height: 60%;
    }

    .message{
    margin-top: 0
    }

    .errorImage{
        width: 18px;
    }
}

@media(max-width: 800px){
    .contactForm>form  label::after{
    content: '';
    }

    .inputContainer{
        display: block;
    } 
    .inputContainer input , .inputContainer textarea{
        display: block;
        width: 90%;
    }
    .closeFormImage{
         width: 30px;
    }
}