.speech{
    height: 100%;
    width: 35%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media(max-width: 1000px){
    .speech{
        margin-top: 10vh;
        width: 80%;
        margin-bottom: 5vh;
    }
}