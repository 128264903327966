.myFooter{
    bottom: 5;
    width: 100%;
    height: 5vh;
    position: absolute;
    background-color: rgba(0,0,0,.8);

    color: var(--main);

    display: flex;
    justify-content: center;
    align-items: center;
}

.myFooter a{
    color: var(--basic);
    transition: all .1s ease-in;
    margin-left: 5px;
}
.myFooter a:hover{
    display: inline-block;
    color: var(--resalt);
    transform: scale(1.2);
}