/* Card */
.tech-card {
    width: 25%;
    height: 4.5rem;
    margin: .3rem 0;
    padding: .5rem;

    background-color: white;
    border-radius: 3px;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    border: 1px solid black;
}

.tech-card:hover {
    cursor: pointer;
    transform: scale(1.3);
    transition: all .15s ease-in;
}

/* Title */
.tech-card h6 {
    font-size: 1rem;
    white-space: pre-line;
}

/* Img */
.tech-card img {
    max-width: 2.5rem;
    height: auto;
}


@media (min-width:800px) and (max-width: 1000px) {
    .tech-card {
        width: 20%;
    }
}