.speech-head{
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* Photo */
.speech-head figure{
    width: 40%;
}

.speech-head .photo{
   width: 100%;
   display: inline-block;
   border-radius: 50%;
   margin: 0 auto;
}
.speech-head .photo:hover{
    cursor: pointer;
}


/* Links */
.speech-head .links{
    width: 40%;
    height: 20vh;

    list-style: none;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.speech-head .links .link{
    display: flex;
    align-items: center;
    font-size: 1.3rem;
}
.speech-head .links .link:hover{
    transform: scale(1.2);
}

.speech-head .links .link img{
    width: 20%;
    margin-right: 5px;
}

/* Effect */
.rotating{
    transform: rotate(1080deg);
    transition: all 3s ease-in-out;
}