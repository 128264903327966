.project-card{
    width: 25%;
}

/* Card title */
.project-card .card-title{
    font-size: 1.2rem;
    background-color: rgba(255, 255, 255, .5);
    padding: .2rem .5rem;
    border-radius: 5px;
    z-index: 1000;

    transition: all .5s ease-in;
}


/* Card info*/
.project-card .card-info{
    height: fit-content;
    position: relative;
    top: -2rem;

    padding: 10px;
    border-radius: 3px;

    background-color: rgba(255, 255, 255, .5);
    backdrop-filter: blur(5px);
    margin-top: .5rem;

    opacity: 1;

    overflow: hidden;
     animation: uncollapse .4s ease-in;
}

.project-card .collapsed{
    height: 0;
    display: none;
    padding: 0 10px;
    overflow: hidden;

    animation: collapse .3s ease-in; 
}

.project-card .card-info p{ 
    font-weight: 400;
    height: fit-content;
}

.project-card .collapsed p{
    opacity: 0;
    height: 0;
    display: none;
}

.project-card .card-info strong{
    font-weight: 700;
    color: var(--replica2);
    font-size: 1.1rem;
}


/* On hover card */

.project-card .overlap{
    border-radius: 5px;
    padding: 5px;
    position: relative;
    top: -1.9rem;
    width: min-content;
    color: var(--base);
    backdrop-filter: blur(5px);

    z-index: 1000;
}



@media(max-width: 1000px){
    .project-card{
        width: 70%;
        margin: 0 auto;
    }
}

/* animation */
@keyframes collapse{
    0%{
        max-height: 80vh;
        opacity: 1;
    }
    100%{
        opacity: 0;
        max-height: 0;
    }
}

@keyframes uncollapse{
    0%{
        max-height: 0;
        opacity: 0;
    }  
    75%{
        opacity: 1;
    }
    100%{
        max-height: 80vh;
    }
}