.speech-body{
    height: 60%;
    min-height: fit-content;
    
    padding: 1.5rem 1.5rem;
    
    margin-top: 10px;
    
    background-color: rgba(0,0,0, .3);
    backdrop-filter: blur(5px);
    border-radius: 3px;
    width: 100%;

    line-height: 1.5rem;
}

.speech-body .text{
    margin-bottom: 5px;
}

@media(max-width: 1000px){
    .speech-body{
        padding: 1.5rem 1rem;
        width: 90%;
    }
}

