.home{
    color: var(--basic);
    width: 100%;

    content-visibility: auto
}

/* BACKGROUND */
.hero::after{
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    
    background: url('./images/code.webp')  fixed;
    background-size: cover;
    
    filter: blur(1.5px) brightness(.7);
    opacity: .8;
}


.hero{
    min-height: 100vh;
    position: relative;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}



/* TEXT */
.hero .info-container{
    min-height: 80vh;
    width: 90%;

   display: flex;
   flex-direction: column;
   justify-content: space-around;
   align-items: center;
}


.hero .name{
    font-size: 5rem;
    font-weight: 400;
}

.hero .title-container{
    text-align: right;
}
.hero .title{
    font-style: italic;
    font-size: 3rem;
}

.hero .title-extension-container{
    text-align: right;
}
.hero .title-extension{
    font-style: italic;
    font-size: 2rem;
    font-weight: 400;
    font-family: 'Merienda', "cursive";
    color: var(--basic);


    overflow: hidden;
    white-space: nowrap;
    padding-right: 10px ;
    margin: 0 0 0 auto;
    border-right: .15em solid white;
    animation:  blink-caret .75s step-end infinite 3.5s;
    transform: translateZ(0)
}


/* PHRASE */

.hero .phrase-container{
    text-align: left;
    width: 80%;
}
.hero .phrase{
    font-weight: 400;
    font-size: 1.2rem;
}


/* ARROW */
.hero .arrow-container{
    position: absolute;
    bottom: 5%;
    width: 100%;

    text-align: center;
}

.hero .arrow-container img{
    width: 80px;
    transform: rotate(-90deg);
    position: relative;
    
    animation: top-bottom 2.5s infinite;
}


/* Animation */
@keyframes top-bottom{
    0%{
        bottom: 0;
    }
    20%{
        bottom: -10px;
    }

    30%{
        bottom: 0;
    }
    40%{
        bottom: -10px;
    }
    50%{
        bottom: 0;
    }
}

/* Querys */
@media(max-width: 900px){
    .hero .name{
        font-size: 3rem;
    }

    .hero .title{
        font-size: 2rem;
    }

    .hero .title-extension{
        font-size: 1.5rem;
    }

    .hero .phrase{
       font-size: 1rem; 
    }

    .hero .arrow-container img{
        width: 50px;
    }

    .hero .arrow-container{
        bottom: 10%;
    }
}

@media(max-width: 550px){
    .hero .name{
        font-size: 2rem;
    }

    .hero .title{
        font-size: 1.5rem;
    }

    .hero .title-extension{
        font-size: 1.1rem;
    }

    .hero .phrase{
       font-size: .9rem; 
    }
}

@media(max-width: 330px){
    .hero .name{
        font-size: 1.5rem;
    }

    .hero .title{
        font-size: 1.2rem;
    }

    .hero .title-extension{
        font-size: .9rem;
    }

    .hero .phrase{
       font-size: .6rem; 
    }

}