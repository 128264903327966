.soft-skills{
    width: 35%;
}

/* Header */
.soft-skills .header{
    font-size: 2rem;
    padding-bottom: 2vh;
    margin-bottom: 2vh;
    border-bottom: 1px solid var(--basic);
}

.soft-skills ul{
    list-style: none;
}

@media(max-width: 1000px){
    .soft-skills{
        width: 80%;
        height: 100%;
    }
}