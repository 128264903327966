/* Montserrat: {
  normal: 300, 400
  italic: 300
} */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;1,300&display=swap');

/* Work Sans : 400 */
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@500&display=swap');


/* Merienda: 400 */
@import url('https://fonts.googleapis.com/css2?family=Merienda&display=swap');



* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2rem;
}

.hidden {
  display: none;
}

.flex {
  display: flex;
}

img {
  width: 100%;
}

body,
input,
textarea,
h1,
h2,
h3,
h4,
h5,
a,
button {
  font-family: "Montserrat", "sans-seriff"
}

p,
h6 {
  font-family: 'Work Sans', 'sans-seriff';
}


/* Animations */
@keyframes blink-caret {

  from,
  to {
    border-color: transparent
  }

  50% {
    border-color: white;
  }
}