.description{
    height: 100%;
    min-height: 100vh;
    position: relative;

    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-around;

    color: var(--basic);

    content-visibility: auto
}
.description::after{
    content: "";

    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    background:  fixed  url('./images/background.webp');
    background-size: cover;
    filter: opacity(.4) blur(2px) brightness(2);
}

@media(max-width: 1000px){
    .description{
        flex-direction: column;
        padding-bottom: 8vh;
    }
}