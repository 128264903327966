.tech-group {
    width: 30%;
    background-color: rgba(255, 255, 255, .5);
    backdrop-filter: blur(3px);
    border-radius: 5px;
    text-align: center;
}

/* Card Title */
.tech-group .group-title {
    margin: 5% auto 2% auto;
    margin-bottom: 5%;
    padding-bottom: 2%;

    border-bottom: 1px solid black;
    width: 90%;

    font-size: 1.5rem;
}

/* Cards Group */

.tech-group .cards-group {
    display: flex;
    height: 70%;

    flex-wrap: wrap;
    align-items: center;
    align-content: flex-start;
    justify-content: space-evenly;
    margin-bottom: 5%;
}


/* Medai querys */
@media(max-width: 1000px) {
    .tech-group {
        margin: 2%;
        width: 90%;
    }
}